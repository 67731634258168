import React from "react"
import styled from "styled-components"
import values from "../../utils/values"

const MainWrap = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;

  @media (min-width: ${values.DEVICESIZE.mobileL}) {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  @media (min-width: ${values.DEVICESIZE.tablet}) {
    //padding-top: 100px;
    //padding-bottom: 100px;
  }
  @media (min-width: ${values.DEVICESIZE.laptop}) {
    padding-top: 130px;
    padding-bottom: 130px;
  }
`

const MaxContentSec = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 0 auto;

  @media (min-width: ${values.DEVICESIZE.mobileL}) {
    min-width: 450px;
    width: 85%;
  }

  @media (min-width: ${values.DEVICESIZE.laptop}) {
    //min-width: 920px;
    //width: 90%;
  }

  @media (min-width: 1280px) {
    max-width: 1920px;
    width: 75%;
  }
`

export default function MainContentWrap({ children }) {
  return (
    <MainWrap>
      <MaxContentSec>{children}</MaxContentSec>
    </MainWrap>
  )
}
