import React from "react"
import debounce from "lodash.debounce"
import styled from "styled-components"
import values from "../../utils/values"
import withMenuColor from "../../hocs/withMenuColor"

const ColorDiv = styled.div`
  background-color: ${props => props.wrapperBgColor};
  color: ${props => props.textColor || values.COLORS.darkTextColor};
  width: 100%;
  height: 100%;
  min-height: 450px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (min-width: ${values.DEVICESIZE.mobileS}) {
    min-height: 600px;
  }
  @media (min-width: 600px) {
    min-height: 800px;
  }
  @media (min-width: ${values.DEVICESIZE.tablet}) {
    min-height: 950px;
  }
  @media (min-width: ${values.DEVICESIZE.laptop}) {
    min-height: 1000px;
  }
  @media (min-width: ${values.DEVICESIZE.laptopL}) {
    min-height: 1100px;
  }
  @media (min-width: 1920px) {
    //min-height: 800px;
  }
`

class ColorThemeWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.rootRef = React.createRef()
  }

  componentDidMount() {
    const target = this.rootRef.current

    const options = {
      rootMargin: "0% 0% -96.75% 0%",
    }

    if (typeof window.IntersectionObserver === `function`) {
      this.observer = new window.IntersectionObserver(this.observe, options)

      this.observer.observe(target)
    }
  }

  componentWillUnmount() {
    this.observer.unobserve(this.rootRef.current)
  }

  observe = debounce(entries => {
    const entry = entries.slice(0).shift()
    if (entry.isIntersecting) {
      // console.log("i am intersected")
      this.props.colorMenuContext.setMenuColor("light")
    } else {
      this.props.colorMenuContext.setMenuColor("dark")
    }
    this.props.colorMenuContext.setIsOnDarkArea(entry.isIntersecting)
  }, 100)

  render() {
    const { wrap1, wrap2, wrap3 } = this.props
    return (
      <>
        <ColorDiv wrapperBgColor={values.COLORS.secondLightBackgroundColor}>
          {wrap1}
        </ColorDiv>
        <ColorDiv wrapperBgColor={values.COLORS.mainLightBackgroundColor}>
          {wrap2}
        </ColorDiv>
        <ColorDiv
          ref={this.rootRef}
          className="black-wrapper"
          wrapperBgColor={values.COLORS.darkBackgroundColor}
          textColor={values.COLORS.lightTextColor}
        >
          {wrap3}
        </ColorDiv>
      </>
    )
  }
}

export default withMenuColor(ColorThemeWrapper)
