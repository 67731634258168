import React from "react"

import SEO from "../components/seo"
import ColorThemeWrapper from "../components/Wrappers/ColorThemeWrapper"
import MainContentWrap from "../components/Wrappers/MainContentWrap"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ColorThemeWrapper
      wrap1={
        <MainContentWrap>
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </MainContentWrap>
      }
    />
  </>
)

export default NotFoundPage
